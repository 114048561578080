







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      net: 'Cart/net',
      discount: 'Cart/discount',
      fullPrice: 'Cart/fullPrice',
      totalPrice: 'Cart/totalPrice'
    })
  }
})
export default class PriceSummary extends Vue {
  public readonly net!: any

  @Prop()
  sumAmount!: number

  fullPrice!: any

  totalPrice!: any

  discount!: any

  showFullPriceFormat!: any
}
